import { navigate } from "gatsby-link"
import React, { useEffect } from "react"

const IndexPage = () => {
  useEffect(() => {
    navigate("/app/login")
  })
  return <></>
}

export default IndexPage
